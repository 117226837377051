import * as React from "react"

import Layout from "../components/layout"
import PageHeader from "../components/pageheader"
import PageBody from "../components/pagebody"
import Seo from "../components/seo"

const NotFoundPage = ( { location }) => (
  <Layout currPage='404' location={location}>
      <Seo title="404 page not found"/>
      <PageHeader pageTitle="404 not found"/>
      <PageBody>
          <p>The page you requested could not be found</p>
      </PageBody>
  </Layout>
)

export default NotFoundPage
